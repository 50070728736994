* {
    font-family: "Source Code Pro", serif;
}
/*
#services *, #contact *, #portfolio * {
    font-family: Arial, Helvetica, sans-serif !important;
}
*/
.theme-bold{
    font-weight: bold;
}

.great-margin-top{
    margin-top: 250px;
}

.theme-main-back {
    background-image: url("world-map3.png");
    background-repeat: no-repeat;
    min-height: 626px;
    background-size: 100%;
    margin-top: 5%;
}

.theme-remarks-color {
    color: #ff805a;
}

.theme-contrast-color {
    color: #666666;
}

.theme-extra-size {
    font-size: 5rem;
}

.theme-medium-extra-size {
    font-size: 3rem;
}
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited{
    background-color: #ff805a !important;
}

.btn-outline-primary, .btn-outline-primary:visited {
    color: #ff805a !important;
    border-color: #ff805a !important;
    
}

.btn-outline-primary:hover, .btn-outline-primary:active{
    background-color: #ff805a !important;
    color: #ffffff !important;
}